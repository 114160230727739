import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Logo from '../components/Logo';
import Image from '../images/nobull.png';
import '../main.css';

const Splash = styled.div`
    display: flex;
    height: 100vh;
    width: 100vw;
    background: black;
    justify-content: center;
    align-items: center;

    img {
        object-fit: contain;
        width: 90%;
        max-width: 600px;
    }
`;

const IndexPage = () => {
    return (
        <Splash>
            <Helmet>
                <title>Otabo</title>
            </Helmet>
            <Logo light />
        </Splash>
    )
}

export default IndexPage
